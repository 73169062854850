<template>

  <div id="loading">
    <!-- <b-card>
      <b-card-header class="pb-50">
        <h5>
          Filters
        </h5>
      </b-card-header>
      <b-card-body>
        <b-row>
          <b-col
            cols="12"
            md="4"
            class="mb-md-0 mb-2"
          >
            <label>Tags</label>
            <v-select
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :value="roleFilter"
              :options="roleOptions"
              class="w-100"
              :reduce="val => val.value"
              @input="(val) => $emit('update:roleFilter', val)"
            />
          </b-col>
          <b-col
            cols="12"
            md="4"
            class="mb-md-0 mb-2"
          >
            <label>Plan</label>
            <v-select
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :value="planFilter"
              :options="planOptions"
              class="w-100"
              :reduce="val => val.value"
              @input="(val) => $emit('update:planFilter', val)"
            />
          </b-col>
          <b-col
            cols="12"
            md="4"
            class="mb-md-0 mb-2"
          >
            <label>Status</label>
            <v-select
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :value="statusFilter"
              :options="statusOptions"
              class="w-100"
              :reduce="val => val.value"
              @input="(val) => $emit('update:statusFilter', val)"
            />
          </b-col>
        </b-row>
      </b-card-body>
    </b-card> -->
    <!-- Table Container Card -->
    <b-card
      no-body
      class="mb-0"
    >
      <div class="m-2">

        <!-- Table Top -->
        <b-row>

          <b-col
            cols="12"
            md="6"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          />

          <!-- Search -->
          <b-col
            cols="12"
            md="6"
          >
            <div class="d-flex align-items-center justify-content-end">
              <b-form-input
                v-model="filter"
                class="d-inline-block mr-1"
                placeholder="Search..."
              />
              <b-button
                :to="{ name: 'add-commercial' }"
                variant="primary"
              >
                <span class="text-nowrap">Add Commercial</span>
              </b-button>
              <business-list-add-new />
            </div>
          </b-col>
        </b-row>

      </div>
      <b-table
        ref="table"
        :items="myProvider"
        :busy.sync="isBusy"
        :fields="tableColumns"
        :current-page="currentPage"
        :per-page="perPage"
        aria-busy="true"
        class="position-relative"
        responsive
        primary-key="id"
      >
        <!-- Column: User -->
        <template #cell(name)="data">
          <b-media vertical-align="center">
            <template #aside>
              <b-avatar
                size="32"
                :src="data.item.logo"
                :text="avatarText(data.item.name)"
              />
            </template>
            <b-link
              :to="{ name: 'view-user', params: { id: data.item.id } }"
              class="font-weight-bold d-block text-nowrap"
            >
              {{ data.item.name }}
            </b-link>
            <small class="text-muted">#{{ data.item.id }}</small>
          </b-media>
        </template>

        <!-- Column: Phone -->
        <template #cell(phone)="data">
          {{ data.item.phone_number }}
        </template>

        <!-- Column: City -->
        <template #cell(classification)="data">
          {{ data.item.classification }}
        </template>

        <!-- Column: Actions -->
        <template #cell(actions)="data">
          <b-dropdown
            variant="link"
            no-caret
          >
            <template #button-content>
              <feather-icon
                icon="MoreVerticalIcon"
                size="16"
                class="align-middle text-body"
              />
            </template>
            <b-dropdown-item :to="{ name: 'view-business', params: { id: data.item.id } }">
              <feather-icon icon="FileTextIcon" />
              <span class="align-middle ml-50">View</span>
            </b-dropdown-item>

            <b-dropdown-item
              :to="{ name: 'edit-commercial', params: { id: data.item.id } }"
            >
              <feather-icon icon="EditIcon" />
              <span> Edit </span>
            </b-dropdown-item>
            <b-dropdown-item @click="deleteBS(data.item.id)">
              <feather-icon icon="TrashIcon" />
              <span class="align-middle ml-50">Delete</span>
            </b-dropdown-item>
          </b-dropdown>
        </template>
      </b-table>
      <div
        v-if="loading"
        class="loader"
      />
    </b-card>
    <template>
      <b-row>
        <b-col md="6">
          <b-pagination
            :value="1"
            :total-rows="totalRows"
            :per-page="perPage"
            align="right"
            first-number
            last-number
            class="mb-0 mt-1"
            prev-class="prev-item"
            next-class="next-item"
            @input="currentPage = $event"
          >
            <template #prev-text>
              <feather-icon
                icon="ChevronLeftIcon"
                size="18"
              />
            </template>
            <template #next-text>
              <feather-icon
                icon="ChevronRightIcon"
                size="18"
              />
            </template>
          </b-pagination>
        </b-col>
      </b-row>
    </template>
  </div>
</template>

<script>
import {
  BCard, BRow, BCol, BFormInput, BButton, BTable, BMedia, BAvatar, BLink,
  BBadge, BDropdown, BDropdownItem, BPagination, VBToggle,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import { avatarText } from '@core/utils/filter'
import axios from 'axios'
import BusinessListAddNew from './BusinessListAddNew.vue'

export default {
  components: {
    BCard,
    BRow,
    BCol,
    // eslint-disable-next-line vue/no-unused-components
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    // eslint-disable-next-line vue/no-unused-components
    BBadge,
    // eslint-disable-next-line vue/no-unused-components
    BDropdown,
    // eslint-disable-next-line vue/no-unused-components
    BDropdownItem,
    // eslint-disable-next-line vue/no-unused-components
    BPagination,
    // eslint-disable-next-line vue/no-unused-components
    vSelect,
    BusinessListAddNew,
  },
  directives: {
    'b-toggle': VBToggle,
  },
  data() {
    return {
      rows: [],
      isBusy: false,
      totalRows: 1,
      currentPage: 1,
      perPage: 10,
      perPageOptions: ['10', '20', '30'],
      searchQuery: '',
      selectedId: 0,
      showEdit: false,
      loading: false,
      filter: null,
    }
  },
  setup() {
    const tableColumns = [
      { key: 'name', sortable: true },
      { key: 'email', sortable: true },
      { key: 'phone', sortable: true },
      { key: 'classification', sortable: true },
      { key: 'actions' },
    ]

    return {
      // Filter
      avatarText,
      tableColumns,
    }
  },
  methods: {
    myProvider(ctx) {
      this.loading = true
      const promis = axios.get(`http://entities-microsevice.muslimdo.com/api/internalops/commercial_entity/list/${ctx.currentPage}`)
      return promis.then(res => {
        console.log(res)
        const items = res.data.data.data
        console.log(res.data.data.data)
        // this.rows = res.data.data.data
        // this.currentPage = res.data.data.pagination.current_page
        this.totalRows = res.data.data.total
        return items
      // eslint-disable-next-line no-unused-vars
      }).finally(() => {
        this.loading = false
      // eslint-disable-next-line no-unused-vars
      }).catch(error => [])
    },
    deleteBS(id) {
      this.$swal({
        title: 'Are you sure?',
        text: "You won't be able to revert this!",
        type: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#00A5AF',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, delete it!',
      // eslint-disable-next-line consistent-return
      }).then(result => {
        if (result.isConfirmed) {
          // eslint-disable-next-line no-unused-vars
          axios.delete(`http://entities-microsevice.muslimdo.com/api/internalops/entity/${id}`).then(res => {
            this.$swal(
              'Deleted!',
              'Business has been deleted.',
              'success',
            )
            this.$refs.table.refresh()
          }).finally(() => {
            this.loading = false
          })
        } else if (result.dismiss) {
          return false
        }
      })
    },
  },
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
