<template>
  <div>
    <b-sidebar
      v-if="show"
      id="sidebar-right"
      :backdrop-variant="variant"
      sidebar-class="sidebar-lg"
      title="Add New Business"
      backdrop
      right
      shadow
    >
      <template #default="{ hide }">

        <!-- BODY -->
        <validation-observer
          ref="refFormObserver"
        >
          <!-- Form -->
          <b-form
            class="p-2"
            @submit.enter.prevent=""
            @reset.prevent="resetForm"
          >
            <!-- Name -->
            <validation-provider
              #default="validationContext"
              name="Name"
              rules="required"
            >
              <b-form-group
                label="Name"
                label-for="name"
              >
                <b-form-input
                  id="name"
                  v-model="formData.name"
                  autofocus
                  :state="getValidationState(validationContext)"
                  trim
                  placeholder="Name"
                />
                <b-form-invalid-feedback>
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>

            <!-- Mobile Number -->
            <validation-provider
              #default="validationContext"
              name="Mobile Number"
              rules="required"
            >
              <b-form-group
                label="Mobile Number"
                label-for="mobile-number"
              >
                <b-form-input
                  id="mobile-number"
                  v-model="formData.phone_number"
                  :state="getValidationState(validationContext)"
                  trim
                />

                <b-form-invalid-feedback>
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>

            <!-- Email -->
            <validation-provider
              #default="validationContext"
              name="Email"
              rules="required|email"
            >
              <b-form-group
                label="Email"
                label-for="email"
              >
                <b-form-input
                  id="email"
                  v-model="formData.email"
                  :state="getValidationState(validationContext)"
                  trim
                />

                <b-form-invalid-feedback>
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>

            <!-- Classification -->
            <validation-provider
              #default="validationContext"
              name="Classification"
              rules="required"
            >
              <b-form-group
                label="Classification"
                label-for="classification"
                :state="getValidationState(validationContext)"
              >
                <b-form-select
                  v-model="formData.classification_id"
                  :options="classificationOptions"
                  class="mb-3"
                  value-field="id"
                  text-field="name"
                  disabled-field="notEnabled"
                />
                <b-form-invalid-feedback :state="getValidationState(validationContext)">
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>

            <!-- About -->
            <validation-provider
              #default="validationContext"
              name="About"
              rules="required"
            >
              <b-form-group
                label="About"
                label-for="about"
              >
                <b-form-textarea
                  id="about"
                  v-model="formData.about"
                  :state="getValidationState(validationContext)"
                  placeholder="About"
                />
                <b-form-invalid-feedback>
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>

            <!-- Form Actions -->
            <div class="d-flex mt-2">
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="primary"
                class="mr-2"
                type="submit"
                @click="addBS()"
              >
                Add
              </b-button>
              <b-button
                v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                type="button"
                variant="outline-secondary"
                @click="hide"
              >
                Cancel
              </b-button>
            </div>

          </b-form>
        </validation-observer>
      </template>
    </b-sidebar>
  </div>
</template>

<script>
import {
  BSidebar, BForm, BFormGroup, BFormInput, BFormInvalidFeedback, BButton, BFormTextarea, BFormFile, BFormSelect,
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
// eslint-disable-next-line no-unused-vars
import { ref } from '@vue/composition-api'
import formValidation from '@core/comp-functions/forms/form-validation'
import { required, alphaNum, email } from '@validations'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
import axios from 'axios'
import VueSweetalert2 from 'vue-sweetalert2'
import Vue from 'vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

Vue.use(VueSweetalert2)

export default {
  components: {
    BSidebar,
    BForm,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    // eslint-disable-next-line vue/no-unused-components
    BButton,
    // eslint-disable-next-line vue/no-unused-components
    vSelect,
    BFormTextarea,
    // eslint-disable-next-line vue/no-unused-components
    BFormFile,

    // form valodation
    ValidationProvider,
    ValidationObserver,
    BFormSelect,
    // eslint-disable-next-line vue/no-unused-components
    ToastificationContent,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      variant: 'dark',
      required,
      alphaNum,
      email,
      show: true,
      typeUrl: 'https://entities-microsevice.muslimdo.com/api/internalops/entity_types/list',
      classificationUrl: 'https://entities-microsevice.muslimdo.com/api/internalops/entity_classifications/list',
      typeOptions: [],
      classificationOptions: [],
      formData: {
        name: '',
        type_id: '2',
        classification_id: '',
        phone_number: '',
        email: '',
        about: '',
      },
    }
  },
  setup() {
    const {
      refFormObserver,
      getValidationState,
      resetForm,
    } = formValidation()

    return {
      refFormObserver,
      getValidationState,
      resetForm,
    }
  },
  created() {
    this.getType()
    this.getClassification()
  },
  methods: {
    getType() {
      axios.get(this.typeUrl).then(res => {
        this.typeOptions = res.data.data.data
      })
    },
    getClassification() {
      axios.get(this.classificationUrl).then(res => {
        this.classificationOptions = res.data.data.data
      })
    },
    addBS() {
      axios.post('https://entities-microsevice.muslimdo.com/api/internalops/entity', this.formData).then(res => {
        if (res.data.status === 200) {
          this.show = false
          this.$swal(
            'Added!',
            'New Business has been Added.',
            'success',
          )
          this.$parent.$refs.table.refresh()
        // eslint-disable-next-line no-empty
        } else {}
      }).catch(err => {
        if (err.response.data.errors.name) {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: err.response.data.errors.name.toString(),
              icon: 'BellIcon',
            },
          })
        } if (err.response.data.errors.phone_number) {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: err.response.data.errors.phone_number.toString(),
              icon: 'BellIcon',
            },
          })
        } if (err.response.data.errors.email) {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: err.response.data.errors.email.toString(),
              icon: 'BellIcon',
            },
          })
        }
      })
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';

.form-main {
    width: 79%;
    margin: auto;
}
</style>
